import React from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import './ClientDatabase.css';

const ClientDatabase = () => {
    const clients = [
        { id: 1, name: 'Mohamed Abdullah', dob: '21/01/1988' },
        { id: 2, name: 'Mohamed Benjamin', dob: '21/01/1988' },
        { id: 3, name: 'Mohamed Chandar', dob: '21/01/1988' },
        { id: 4, name: 'Michael Haart', dob: '21/01/1988' },
    ];

    return (
        <Dashboard>
            <div className="client-database-container">
                <h2><i className="icon">📂</i> Client Database</h2>
                <table className="clients-table">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Date of Birth</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((client) => (
                            <tr key={client.id}>
                                <td>
                                    <Link to={`/client-database/${client.id}`}>
                                        {client.name}
                                    </Link>
                                </td>
                                <td>{client.dob}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dashboard>
    );
};

export default ClientDatabase;