import React, { useState } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './OverrideStatus.css';

const OverrideStatus = () => {
    const [selectedCycle, setSelectedCycle] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedClients, setSelectedClients] = useState([]);

    const clients = [
        { name: 'Mohamed Abdullah', status: 'Good' },
        { name: 'Mohamed Benjamin', status: 'Good' },
        { name: 'Mohamed Chandar', status: 'Attention' },
        { name: 'Michael Haart', status: 'Suspended' },
    ];

    const handleClientSelect = (clientName) => {
        if (selectedClients.includes(clientName)) {
            setSelectedClients(selectedClients.filter(name => name !== clientName));
        } else {
            setSelectedClients([...selectedClients, clientName]);
        }
    };

    return (
        <Dashboard>
            <div className="override-status-container">
                <h2><i className="icon">🔄</i> Override Status</h2>
                <div className="form-group">
                    <label htmlFor="cycleName">Cycle name:</label>
                    <select id="cycleName" value={selectedCycle} onChange={(e) => setSelectedCycle(e.target.value)}>
                        <option value="">Select cycle...</option>
                        <option value="Cycle 1">Cycle 1</option>
                        <option value="Cycle 2">Cycle 2</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="status">Change status:</label>
                    <select id="status" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                        <option value="">Select status...</option>
                        <option value="Good">Good</option>
                        <option value="Attention">Attention</option>
                        <option value="Suspended">Suspended</option>
                    </select>
                </div>
                <table className="clients-table">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Current Client Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((client, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedClients.includes(client.name)}
                                        onChange={() => handleClientSelect(client.name)}
                                    />
                                    {client.name}
                                </td>
                                <td className={`status ${client.status.toLowerCase()}`}>{client.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="form-buttons">
                    <button type="button" className="cancel-button">Cancel</button>
                    <button type="submit" className="save-button">Save</button>
                </div>
            </div>
        </Dashboard>
    );
};

export default OverrideStatus;