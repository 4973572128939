import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './Settings.css';

const Settings = () => {
    return (
        <Dashboard>
            <div className="settings-container">
                <h2><i className="icon">⚙️</i> Settings</h2>
                <p>No data is available!</p>
            </div>
        </Dashboard>
    );
};

export default Settings;