import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const savedAuth = localStorage.getItem('isAuthenticated');
        return savedAuth ? JSON.parse(savedAuth) : false;
    });

    const login = (user) => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('lastActivity', Date.now());
    };

    const logout = useCallback(() => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('user');
        localStorage.removeItem('lastActivity');
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const lastActivity = localStorage.getItem('lastActivity');
            if (lastActivity && Date.now() - lastActivity > 30 * 60 * 1000) {
                logout();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [logout]);

    const updateActivity = () => {
        localStorage.setItem('lastActivity', Date.now());
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, updateActivity }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);