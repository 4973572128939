import React from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import './IndividualClient.css';

const IndividualClient = () => {
    const { clientId } = useParams();
    const client = {
        fullName: 'Mohamed Chandar',
        address: 'Westminster, London SW1A 1AA',
        email: 'Ertugrul@bey.com',
        mobileNumber: '079 9999 9999',
        dateJoined: '12.12.2020',
        documentsProvided: 'Passport, and driver\'s licences',
        dob: '12.12.2020',
        diary: '',
    };

    return (
        <Dashboard>
            <div className="individual-client-container">
                <h2>
                    <i className="icon">📂</i> Client Database > Individual Client
                </h2>
                <div className="form-group">
                    <label htmlFor="fullName">Full Name:</label>
                    <input type="text" id="fullName" value={client.fullName} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address:</label>
                    <input type="text" id="address" value={client.address} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="text" id="email" value={client.email} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input type="text" id="mobileNumber" value={client.mobileNumber} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="dateJoined">Date Joined:</label>
                    <input type="text" id="dateJoined" value={client.dateJoined} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="documentsProvided">Documents provided:</label>
                    <input type="text" id="documentsProvided" value={client.documentsProvided} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="dob">Date of Birth:</label>
                    <input type="text" id="dob" value={client.dob} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="diary">Diary:</label>
                    <textarea id="diary" value={client.diary} readOnly />
                </div>
                <div className="form-group">
                    <label htmlFor="cycleName">Cycle name:</label>
                    <select id="cycleName">
                        <option value="">Select cycle...</option>
                        <option value="Cycle 1">Cycle 1</option>
                        <option value="Cycle 2">Cycle 2</option>
                    </select>
                </div>
                <div className="form-buttons">
                    <button type="button" className="cancel-button">Cancel</button>
                    <button type="submit" className="save-button">Save</button>
                </div>
            </div>
        </Dashboard>
    );
};

export default IndividualClient;