import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './AddClients.css';

const AddClient = () => {
    return (
        <Dashboard>
            <div className="add-client-container">
                <h2><i className="icon">👤</i> Add Client</h2>
                <h3>Client Details</h3>
                <form className="client-form">
                    <label>
                        Full Name:
                        <input type="text" name="fullName" />
                    </label>
                    <label>
                        Address:
                        <input type="text" name="address" />
                    </label>
                    <label>
                        Email:
                        <input type="email" name="email" />
                    </label>
                    <label>
                        Mobile Number:
                        <input type="text" name="mobileNumber" />
                    </label>
                    <label>
                        Date Joined:
                        <input type="date" name="dateJoined" />
                    </label>
                    <label>
                        Documents provided:
                        <input type="text" name="documentsProvided" />
                    </label>
                    <label>
                        Date of Birth:
                        <input type="date" name="dateOfBirth" />
                    </label>
                    <label>
                        Diary:
                        <textarea name="diary"></textarea>
                    </label>
                    <div className="form-buttons">
                        <button type="button" className="delete-button">Delete</button>
                        <button type="button" className="cancel-button">Cancel</button>
                        <button type="submit" className="save-button">Save</button>
                    </div>
                </form>
            </div>
        </Dashboard>
    );
};

export default AddClient;
