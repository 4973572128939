import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import HomeScreen from './screens/Home/Home';
import LoginScreen from './screens/Login/Login';
import Overview from './screens/Overview/Overview';
import AddClient from './screens/AddClients/AddClients';
import ManageCycles from './screens/ManageCycles/ManageCycles';
import OverrideStatus from './screens/OverrideStatus/OverrideStatus';
import ClientDatabase from './screens/ClientDatabase/ClientDatabase';
import IndividualClient from './screens/IndividualClient/IndividualClient';
import Settings from './screens/Setttings/Settings'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

function App() {
    return (
        <Router>
            <Routes>
                {/* <Route path="/" element={<HomeScreen />} /> */}
                <Route path="/" element={<LoginScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <Overview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/add-client"
                    element={
                        <ProtectedRoute>
                            <AddClient />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/manage-cycles"
                    element={
                        <ProtectedRoute>
                            <ManageCycles />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/override-status"
                    element={
                        <ProtectedRoute>
                            <OverrideStatus />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/client-database"
                    element={
                        <ProtectedRoute>
                            <ClientDatabase />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/client-database/:clientId"
                    element={
                        <ProtectedRoute>
                            <IndividualClient />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <ProtectedRoute>
                            <Settings />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;