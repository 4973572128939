import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import lockIcon from '../../assets/lock-icon.png';
import { useAuth } from '../../context/AuthContext';
import { loginUser } from '../../api';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async () => {
        try {
            const data = await loginUser(username, password);
            login(data);  // Assuming the response data contains user information
            navigate('/dashboard');
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="login-container">
            <h1 className="login-title">AHS</h1>
            <div className="login-logo">
                <img src={lockIcon} alt="Lock" />
            </div>
            <input 
                type="text" 
                placeholder="Username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                className="login-input"
            />
            <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                className="login-input"
            />
            <button 
                className="forgot-password" 
                onClick={() => alert('Forgot password clicked')}
            >
                Forgot password?
            </button>
            <button onClick={handleLogin} className="login-button">Login</button>
        </div>
    );
};

export default Login;