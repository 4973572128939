import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import './Dashboard.css';

const Dashboard = ({ children }) => {
    return (
        <div className="dashboard-container">
            <Sidebar />
            <div className="main-content">
                {children}
            </div>
        </div>
    );
};

export default Dashboard;