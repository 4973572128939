// src/api.js
import urls from './urls';

const apiRequest = async (endpoint, method, body) => {
    const response = await fetch(endpoint, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong');
    }

    return response.json();
};

export const loginUser = (username, password) => {
    return apiRequest(urls.login, 'POST', { username, password });
};