import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { useAuth } from '../../context/AuthContext';

const Sidebar = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <div className="sidebar">
            <h1 className="logo">AHS</h1>
            <nav className="nav-menu">
                <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <i className="icon">🏠</i> Overview
                </NavLink>
                <NavLink to="/add-client" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <i className="icon">👤</i> Add Client
                </NavLink>
                <NavLink to="/manage-cycles" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <i className="icon">👥</i> Manage Cycles
                </NavLink>
                <NavLink to="/override-status" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <i className="icon">🔄</i> Override Status
                </NavLink>
                <NavLink to="/client-database" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <i className="icon">📂</i> Client Database
                </NavLink>
                <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                    <i className="icon">⚙️</i> Settings
                </NavLink>
            </nav>
            <button className="logout-button" onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Sidebar;