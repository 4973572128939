import React, { useState } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './ManageCycles.css';

const ManageCycles = () => {
    const [activeTab, setActiveTab] = useState('current');
    const [isCreatingCycle, setIsCreatingCycle] = useState(false);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [cycleName, setCycleName] = useState('');
    const [cycleAmount, setCycleAmount] = useState('');
    const [payoutDate, setPayoutDate] = useState('');
    const [cycleStartDate, setCycleStartDate] = useState('');
    const [cycleEndDate, setCycleEndDate] = useState('');

    const participants = [
        'Mohamed A',
        'Mohamed Abdullah',
        'Mohamed Benjamin',
        'Mohamed Chandar'
    ];

    const handleParticipantSelect = (participant) => {
        if (!selectedParticipants.includes(participant)) {
            setSelectedParticipants([...selectedParticipants, participant]);
        }
    };

    const handleParticipantRemove = (participant) => {
        setSelectedParticipants(selectedParticipants.filter(p => p !== participant));
    };

    const renderContent = () => {
        if (isCreatingCycle) {
            return (
                <div className="content">
                    <div className="form-group">
                        <label htmlFor="cycleName">Cycle name</label>
                        <input type="text" id="cycleName" value={cycleName} onChange={(e) => setCycleName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="cycleAmount">Cycle amount</label>
                        <input type="text" id="cycleAmount" value={cycleAmount} onChange={(e) => setCycleAmount(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="searchParticipants">Participants</label>
                        <input type="text" id="searchParticipants" placeholder="Search participants..." />
                    </div>
                    <ul className="participant-list">
                        {participants.map((participant, index) => (
                            <li key={index} onClick={() => handleParticipantSelect(participant)}>
                                {participant}
                                <button className="add-button">+</button>
                            </li>
                        ))}
                    </ul>
                    {selectedParticipants.length > 0 && (
                        <>
                            <p>{selectedParticipants.length} Participant(s) have been selected:</p>
                            <ul className="selected-participant-list">
                                {selectedParticipants.map((participant, index) => (
                                    <li key={index}>
                                        {participant}
                                        <button onClick={() => handleParticipantRemove(participant)}>🗑️</button>
                                    </li>
                                ))}
                            </ul>
                            <div className="form-group">
                                <label htmlFor="payoutDate">Payout date</label>
                                <input type="date" id="payoutDate" value={payoutDate} onChange={(e) => setPayoutDate(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cycleStartDate">Cycle starting date</label>
                                <input type="date" id="cycleStartDate" value={cycleStartDate} onChange={(e) => setCycleStartDate(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cycleEndDate">Cycle ending date</label>
                                <input type="date" id="cycleEndDate" value={cycleEndDate} onChange={(e) => setCycleEndDate(e.target.value)} />
                            </div>
                        </>
                    )}
                </div>
            );
        }

        if (activeTab === 'current') {
            return (
                <div className="content">
                    <button className="create-cycle-button" onClick={() => setIsCreatingCycle(true)}>+ Create cycle</button>
                    <p>There are currently no running cycles to view!</p>
                </div>
            );
        } else if (activeTab === 'previous') {
            return (
                <div className="content">
                    <p>There are no previous cycles to view!</p>
                </div>
            );
        }
    };

    return (
        <Dashboard>
            <div className="manage-cycles-container">
                <h2><i className="icon">👥</i> Manage Cycles</h2>
                <div className="tabs">
                    <button
                        className={`tab ${activeTab === 'current' ? 'active' : ''}`}
                        onClick={() => { setActiveTab('current'); setIsCreatingCycle(false); }}
                    >
                        Current cycle
                    </button>
                    <button
                        className={`tab ${activeTab === 'previous' ? 'active' : ''}`}
                        onClick={() => { setActiveTab('previous'); setIsCreatingCycle(false); }}
                    >
                        Previous cycles
                    </button>
                </div>
                {renderContent()}
                <div className="form-buttons">
                    <button type="button" className="cancel-button" onClick={() => setIsCreatingCycle(false)}>Cancel</button>
                    <button type="submit" className="save-button">Save</button>
                </div>
            </div>
        </Dashboard>
    );
};

export default ManageCycles;