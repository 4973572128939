import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import './Overview.css';

const Overview = () => {
    const dummyData = {
        cycleStartDate: '22/01/2020',
        cycleEndDate: '22/07/2020',
        outstandingDebt: '£0',
        totalLateDeposits: 0,
        cycleAmount: '£1000,00',
        totalClientsCovered: 0,
        upcomingPayoutDate: '31.05.2020',
        upcomingClient: 'Anuj',
        participants: [
            { name: 'Muhammad Ali', lastUpdated: '12.11.2019', upcomingPayout: '12.12.2019', status: 'Good' },
            { name: 'Sonny Liston', lastUpdated: '12.11.2019', upcomingPayout: '12.12.2019', status: 'Good' },
            { name: 'Joe Frazier', lastUpdated: '12.11.2019', upcomingPayout: '12.12.2019', status: 'Attention' },
            { name: 'Michael Haart', lastUpdated: '12.11.2019', upcomingPayout: '12.12.2019', status: 'Suspended' }
        ]
    };

    return (
        <Dashboard>
            <div className="overview-container">
                <h2>Status Tracker</h2>
                <div className="status-header">
                    <p>Cycle starting date: {dummyData.cycleStartDate}</p>
                    <p>Cycle ending date: {dummyData.cycleEndDate}</p>
                </div>
                <div className="status-cards">
                    <div className="status-card">
                        <p>{dummyData.outstandingDebt}</p>
                        <p>Outstanding Debt to AHS</p>
                    </div>
                    <div className="status-card">
                        <p>{dummyData.totalLateDeposits}</p>
                        <p>Total Late Deposits</p>
                    </div>
                    <div className="status-card">
                        <p>{dummyData.cycleAmount}</p>
                        <p>Cycle Amount</p>
                    </div>
                    <div className="status-card">
                        <p>{dummyData.totalClientsCovered}</p>
                        <p>Total Clients Covered</p>
                    </div>
                    <div className="status-card">
                        <p>{dummyData.upcomingPayoutDate}</p>
                        <p>Upcoming Payout Date</p>
                    </div>
                    <div className="status-card">
                        <p>{dummyData.upcomingClient}</p>
                        <p>Upcoming Client To Pay</p>
                    </div>
                </div>
                <table className="participants-table">
                    <thead>
                        <tr>
                            <th>Participant(s): {dummyData.participants.length}</th>
                            <th>Last Updated</th>
                            <th>Upcoming Payout</th>
                            <th>Client Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dummyData.participants.map((participant, index) => (
                            <tr key={index}>
                                <td>{participant.name}</td>
                                <td>{participant.lastUpdated}</td>
                                <td>{participant.upcomingPayout}</td>
                                <td className={participant.status.toLowerCase()}>{participant.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dashboard>
    );
};

export default Overview;